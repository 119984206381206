import React, {useState, useCallback, useEffect, useContext, ChangeEvent} from 'react';
import {Recipe, ResourceType, Ingredient, Addition, missingPlant} from '../../types'
import StepContent from '@material-ui/core/StepContent';
import {
  Stepper,
  Step,
  Button,
  makeStyles,
  Theme,
  Box,
  CardActions,
  StepButton,
  Divider,
  DialogContent,
  TextField
} from '@material-ui/core';
import logeSteps, { consistencyClassStep } from './logeSteps';
import Summary from './Summary';
import bindersMixtureStep from './bindersMixtureStep';
import excipientsExtrasStep from './excipientsExtrasStep';
import additionsStep from './additionsStep';
import attestStep from './attestStep';
import ConfirmationStep from './ConfirmationStep';
import gradingCurveStep from './gradingCurveStep';
import plantsStep from "./PlantsStep";
import {
  getVolume,
  getBinderTotal,
  getPercentageFine,
  getErrors, getPercentageSand, getSands, getGravels
} from '../../computed/recipeComputations';
import { useLocation, useHistory } from 'react-router-dom';
import { BreadcrumbsContext } from '../../views/PageWithBreadcrumbs';
import ConfirmErrorsDialog, { ConfirmErrorsDialogProps } from './ConfirmErrorsDialog';
import { EnhancedRecipe } from '../../pages/Recipes';
import {Settings} from "../../config/settings";
import {getAll as getResources, getPlant, validatePlants} from "../../HTTPClients/RecipeApp/resources/resources";
import {getResources as getRecipeResources} from "../../HTTPClients/RecipeApp/recipes/recipes"
import {StoreContext} from "../../StoreContextProvider";
import {setPlantRelatedResources} from "./serviceFunctions";
import {MissingPlantsAlert} from "./MissingPlantsAlert";
declare var JsonUrl: any
const codec = JsonUrl('lzma')

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  verticalDivider: {
    borderLeftColor: theme.palette.divider,
    borderLeftWidth: 1,
    borderLeftStyle: 'solid'
  }
}));

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Step = {
  title: string,
  optional?: boolean,
  hasErrors: (recipe: Recipe) => boolean,
  isCompleted: (recipe: Recipe) => boolean,
  optionalText?: (recipe: Recipe) => React.ReactText | JSX.Element | undefined
  StepComponent: React.FC<{ recipe: Recipe, onChange: (recipe: Recipe) => void }>
}

const commonSteps: Step[] = [
  attestStep,
  bindersMixtureStep,
  excipientsExtrasStep,
  additionsStep,
  plantsStep,
  gradingCurveStep
]

type RecipeDialogContentProps = {
  recipe?: Recipe | EnhancedRecipe,
  type?: 'los' | 'loge',
  step: 'overview' | 'edit',
  onSave: (recipe: Recipe, ignoreErrors?: boolean, republish?: boolean) => void
}

let initialStep: number;
const initialRecipe = { airPercentage: 2, environmentClasses: [], ingredients: [], plants: [], families: [] } as unknown as Recipe

const RecipeDialogContent: React.FC<RecipeDialogContentProps> = ({ type, onSave, step, ...props }) => {
  const [confirmDialogProps, confirmErrors] = useState({ open: false } as ConfirmErrorsDialogProps);
  const [recipe, setRecipe] = useState(initialRecipe)
//  const [plants, setPlants] = useState([])
  const plants=useContext(StoreContext).plants;
  const [plantId, setPlantId] = useState(0)
  const [defaultPlant, setDefaultPlant] = useState(true)
  const [activeStep, setActiveStep] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);
  const [name, setName] = useState(props.recipe!==undefined ? props.recipe.recipeName : '');
  const steps = [...(type === 'los' ? [consistencyClassStep] : logeSteps), ...commonSteps];
  const { button, verticalDivider } = useStyles();
  const { search, pathname, state } = useLocation()
  const history = useHistory()
  const { setToolbarButton } = useContext(BreadcrumbsContext)

  const gotoOverview = useCallback(() => {
    history.push(`/recipe/${props.recipe && props.recipe.id ? props.recipe.id : `add/${type}`}/overview${search}`, state)
  }, [history, props.recipe, type, search, state])

  useEffect(() => {
    // @ts-ignore
    step !== 'overview' ? setToolbarButton(<Button size="large" variant="contained" style={{marginRight: '2rem'}} color="secondary" onClick={() => gotoOverview()}>Naar bevestiging</Button>) : setToolbarButton(state && state.previous ? <Button size="large" variant="contained" color="secondary" onClick={() => history.push(state.previous)}>Terug naar revisies overzicht</Button> : null)
  }, [setToolbarButton, step, history, props.recipe, state, gotoOverview])

  useEffect(() => {
    props.recipe && setRecipe(props.recipe);
  },[props.recipe])

  useEffect(() => {
    recipe.binderTotal = getBinderTotal(recipe.ingredients, Boolean(recipe.attest));
    recipe.density = recipe.ingredients.reduce((sum, r) => sum += r.amount, 0);
    recipe.percentageFine = getPercentageFine(recipe.ingredients, recipe);
  },[recipe])

  useEffect(() => {
    if (search.length > 3 && (recipe === initialRecipe)) {
      (async () => {
        const decompressed = await codec.decompress(search.substr(3))
        setRecipe(decompressed)
        setName(decompressed.recipeName)
      })()
    }
  }, [search, recipe])

  useEffect(() => {
    initialStep = 0;
    if(plants.length)
      setPlantId(Settings.default_plant_id)
  },[plants, setPlantId]);
  useEffect(() => {
    if (!initialStep) {
      const los = type === 'los';

      if(!recipe.plants.length) {
        let index=plants.findIndex(p=>p.id===plantId)
        if(index>-1)
          recipe.plants.push(plants[index])
      }

      recipe.strengthClass && (initialStep = 0);
      recipe.environmentClasses && recipe.environmentClasses.length > 0 && (initialStep = 1);
      recipe.consistencyClass && (initialStep = los ? 0 : 2);
      recipe.typeOfWork && (initialStep = 3);
      recipe.chlorideClass && (initialStep = 4);
      recipe.attest && (initialStep = los ? 1 : 5);
      recipe.ingredients.find(r => [ResourceType.Cement, ResourceType.Filler].indexOf(r.resource.type) >= 0) && (initialStep = los ? 2 : 6);
      recipe.ingredients.find(r => [ResourceType.Excipient, ResourceType.Extra].indexOf(r.resource.type) >= 0) && (initialStep = los ? 3 : 7);
      recipe.ingredients.find(r => r.resource.type === ResourceType.Addition) && (initialStep = los ? 4 : 8);
      recipe.gradingCurve && (initialStep = los ? 5 : 9);

      let additions = recipe.ingredients.filter(r => r.resource.type === ResourceType.Addition) as Array<Ingredient & { resource: Addition }>
      let sandAddition=additions.filter(r => r.resource.isSand);
      if(sandAddition.length)
        calculateAdditions(recipe.ingredients, recipe.airPercentage);

      setActiveStep(initialStep);
    }
  }, [recipe, type, plants]);

  const gotoStep = useCallback((step: number) => {
    step > initialStep && (initialStep = step);
    history.push(`/recipe/${props.recipe && props.recipe.id ? props.recipe.id : `add/${type}`}`, state)

    setTimeout(()=>{
      let element=document.getElementById('step_'+step);
      if(element) element.scrollIntoView({ behavior: 'smooth' });
    },250)

    setActiveStep(step);
  }, [history, props.recipe, type, state]);
  const handlePrevious = useCallback(() => gotoStep(activeStep - 1), [activeStep, gotoStep]);
  const handleNext = useCallback(() => gotoStep(activeStep + 1), [activeStep, gotoStep]);

  const handleChange = useCallback(async (recipe: Recipe) => {
    // If recipe has no water resource, add one
    if (recipe.ingredients.filter(r => r.resource.type === ResourceType.Water).length === 0) {
      getResources().then(
        function(response) {
          const water = response.data.data.find(r => r.type === ResourceType.Water)
          water && setRecipe({ ...recipe, ingredients: [{ amount: 0, resource: water }] })
        }
      )
    }
    const { ingredients } = recipe
    calculateAdditions(ingredients, recipe.airPercentage, getPercentageSand(ingredients))
    setRecipe({ ...recipe, ingredients })

    // Set recipe name when updated in Confirmation step
    if(recipe.recipeName!==name)
      setName(recipe.recipeName)

    if (!recipe.id) {
      const encoded = await codec.compress({ ...recipe, ingredients })
      window.history.pushState({}, '', Settings.URLs.client + pathname + '?r=' + encoded)
    }
  }, [pathname, setRecipe])

  const handlePlantChange = useCallback((plantId: number) => {
    setPlantId(plantId)
    setDefaultPlant(plantId===Settings.default_plant_id)
  },[setPlantId]);

  const nameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setName(value);
    recipe.recipeName=value;
  }, [setName, recipe]);

  const handleSave = useCallback(() => {

    if(!recipe.id) {
      getPlant(Settings.default_plant_id).then(
        function(response) {
          setPlantRelatedResources(recipe, response.data.data)
          const {ingredients}=recipe
          calculateAdditions(ingredients, recipe.airPercentage, getPercentageSand(ingredients))
          setRecipe({...recipe, ingredients})
        }
      )
    } else {
      // Recalculate and Save recipe based on resource data of the default plant setting
      getRecipeResources(recipe.id, Settings.default_plant_id).then(
        function(response) {
          setPlantRelatedResources(recipe, response.data.data)
          const {ingredients}=recipe
          calculateAdditions(ingredients, recipe.airPercentage, getPercentageSand(ingredients))
          setRecipe({...recipe, ingredients})
        }
      )
    }

    recipe.binderTotal = getBinderTotal(recipe.ingredients, Boolean(recipe.attest));
    recipe.density = recipe.ingredients.reduce((sum, r) => sum += r.amount, 0);
    recipe.percentageFine = getPercentageFine(recipe.ingredients, recipe);
    if (getErrors(recipe).length > 0) {
      confirmErrors({
        open: true,
        onCancel: () => confirmErrors({ open: false }),
        onConfirm: async (ignoreErrors: boolean) => {
          onSave(recipe, ignoreErrors);
          confirmErrors({ open: false });
        }
      });
    } else {
      onSave(recipe);
    }
  }, [onSave, recipe]);

  const handleScroll = (e: React.UIEvent<HTMLElement>): void => {
    e.stopPropagation() // Prevent event bubbling to scrollable parent
    setScrollTop(e.currentTarget.scrollTop);
  };

  return step === 'overview' ? (
    <DialogContent style={{ height: '100%' }}>
      <ConfirmationStep recipe={recipe} onChange={handleChange} onSave={handleSave} plants={plants} plantId={plantId} onPlantChange={handlePlantChange} goto={step => gotoStep(step)} />
      <ConfirmErrorsDialog {...confirmDialogProps} recipe={recipe} />
    </DialogContent>
  ) : (
    <Box display="flex" height="100%">
      {name && scrollTop > 50 &&
        <div className={'recipe-name'}>
          Recept: <b>{name}</b>
        </div>
      }
      <Box flex={1} overflow="auto" onScroll={handleScroll}>
        <div className={'recipename'}>
          <TextField
            id={'recipeName'}
            label={'Receptnaam'}
            variant="outlined"
            size={'small'}
            value={name || ''}
            margin="normal"
            fullWidth={true}
            onChange={nameChange}
          />
        </div>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map(({ title, optional, hasErrors, isCompleted, optionalText, StepComponent }, index) => (
            <Step key={index} id={'step_' + index} style={{paddingTop: activeStep===index ? '50px' : '0'}}>
              <StepButton
                onClick={() => gotoStep(index)}
                completed={isCompleted(recipe) && typeof initialStep !== 'undefined' && index <= initialStep}
                optional={index !== activeStep && optionalText && optionalText(recipe)}
                disabled={!defaultPlant || index > initialStep + 1}
                style={{ textAlign: 'left' }}
              >
                {title}
              </StepButton>
              <StepContent>
                <StepComponent recipe={recipe} onChange={handleChange} />
                <div>
                  <Button disabled={activeStep === 0} onClick={handlePrevious} className={button}>Vorige</Button>
                  {activeStep < steps.length - 1 && <Button disabled={hasErrors(recipe) && !optional} variant="contained" color="primary" onClick={handleNext} className={button}>
                    {optional && hasErrors(recipe) ? 'Overslaan' : 'Volgende'}
                  </Button>}
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box width={280} display="flex" flexDirection="column" className={verticalDivider}>
        <Box flex={1} overflow="auto">
          <Summary recipe={recipe} onChange={handleChange} plants={plants} plantId={plantId} onPlantChange={handlePlantChange} />
        </Box>
        <Divider />
        <CardActions>
          <span style={{ flex: 1 }} />
          <Button variant="contained" color="secondary" style={{marginRight: '2rem'}} onClick={gotoOverview}>Naar bevestiging</Button>
        </CardActions>
      </Box>
    </Box>
  );
}

export default RecipeDialogContent;

export function calculateAdditions(ingredients: Ingredient[], airPercentage: number, sandPercentage: number=0) {
  const showConsoleLog=false;

  if(showConsoleLog)
    console.log('sandPercentage voor berekening: '+sandPercentage);

  let additions = ingredients.filter(r => r.resource.type === ResourceType.Addition) as Array<Ingredient & { resource: Addition }>
  if(!sandPercentage || sandPercentage===100) {
    const additionTotal = getVolume(additions)
    const sandTotal = getVolume(additions.filter(r => r.resource.isSand));
    sandPercentage = Math.round(100 / additionTotal * sandTotal);
    if(sandPercentage===100) sandPercentage=50;
  }
  if(showConsoleLog)
    console.log('sandPercentage na berekening: '+sandPercentage);

  let hasSand = additions.filter(a => a.resource.isSand).length > 0;
  let hasGravel = additions.filter(a => !a.resource.isSand).length > 0;
  if(showConsoleLog)
    console.log('hasSand: '+hasSand+' - hasGravel: '+hasGravel);

  let sandMultiplier = 1;
  let gravelMultiplier = 1;
  let sands  =getSands(ingredients);
  let gravels=getGravels(ingredients);
  if(sands.length>1) {
    sandMultiplier = getSandMultiplier(sandPercentage, hasSand, hasGravel);
  }
  if(gravels.length>1) {
    gravelMultiplier = getGravelMultiplier(sandPercentage, hasSand, hasGravel);
  }

  if(sands.length===1 && gravels.length===1) {
    sands[0].percentage=sandPercentage;
    gravels[0].percentage=100-sandPercentage;
  }

  if(showConsoleLog)
    console.log('sandMultiplier: '+sandMultiplier+' - gravelMultiplier: '+gravelMultiplier);

  let total = 1000 - (getVolume(ingredients.filter(r => r.resource.type !== ResourceType.Addition)) + (airPercentage * 10));
  ingredients.forEach((ingredient, index) => {
    if(ingredient.percentage!==undefined && ingredient.resource.type === ResourceType.Addition && ingredient.percentage) {

      if((ingredient.resource as Addition).isSand && sands.length===1) {
        ingredient.percentage=sandPercentage;
      }

      let amount=(total * (ingredient.percentage * ((ingredient.resource as Addition).isSand ? sandMultiplier : gravelMultiplier)) / 100) * ingredient.resource.density / 1000;
      if(showConsoleLog)
        console.log('resource: '+ingredient.resource.name+ ' - percentage: ' + ingredient.percentage + ' - amount: '+amount);
      ingredients[index] = { ...ingredient, amount: amount };
    }
  });

  /*
  const showConsoleLog=false;

  let additions = ingredients.filter(r => r.resource.type === ResourceType.Addition) as Array<Ingredient & { resource: Addition }>
  let sandAdditions=additions.filter(r => r.resource.isSand);
  let gravelAdditions=additions.filter(r => !r.resource.isSand);

  if(showConsoleLog)
    console.log('gravel ingredients: '+gravelAdditions.length);

  if(!sandPercentage) {
    if(showConsoleLog)
      console.log('no sandpercentage given');
    let additionsVolume = getVolume(additions)
    let sandVolume = getVolume(sandAdditions);
    sandPercentage = 100 / additionsVolume * sandVolume;
    if(showConsoleLog)
      console.log('calculated sand percentage: '+sandPercentage);
  } else
    if(showConsoleLog)
      console.log('given sand percentage: '+sandPercentage);

  let availableVolume = 1000 - (getVolume(ingredients.filter(r => r.resource.type !== ResourceType.Addition)));
  availableVolume-=(airPercentage * 10);
  let remainingVolume=availableVolume;

  if(showConsoleLog)
    console.log('available volume: '+availableVolume);

  let hasSand = additions.filter(a => a.resource.isSand).length > 0;
  let hasGravel = additions.filter(a => !a.resource.isSand).length > 0;

  let sandMultiplier=getSandMultiplier(sandPercentage, hasSand, hasGravel);
  if(sandAdditions.length===1)
    sandMultiplier=1;

  if(showConsoleLog)
    console.log('sand multiplier: '+sandMultiplier);

  let gravelMultiplier=getGravelMultiplier(sandPercentage, hasSand, hasGravel);
  if(gravelAdditions.length===1)
    gravelMultiplier=1;
  if(showConsoleLog)
    console.log('gravel multiplier: '+gravelMultiplier);

  // Set the sand percentage
  for(let ingredient of ingredients) {
    if(ingredient.resource.type!==ResourceType.Addition || !(ingredient.resource as Addition).isSand)
      continue;
    ingredient.percentage=sandPercentage;
  }
  if(gravelAdditions.length===1) {
    for(let ingredient of ingredients) {
      if(ingredient.resource.type!==ResourceType.Addition || (ingredient.resource as Addition).isSand)
        continue;
      ingredient.percentage=100-sandPercentage;
    }
  }

  for(let ingredient of ingredients) {
    if(ingredient.resource.type!==ResourceType.Addition || !ingredient.percentage)
      continue;

    if(showConsoleLog)
      console.log('ingredient: '+ingredient.resource.name + ' density: ' + ingredient.resource.density);

    let multiplier=sandMultiplier;
    if(!(ingredient.resource as Addition).isSand)
      multiplier=gravelMultiplier;
    let volume=availableVolume * (ingredient.percentage * multiplier / 100);
    if(showConsoleLog)
      console.log('ingredient volume: '+volume+' percentage: '+ingredient.percentage+'%');

    ingredient.amount=volume * ingredient.resource.density / 1000;
    if(showConsoleLog)
      console.log('ingredient weight: '+ingredient.amount);

    remainingVolume-=volume;
    if(showConsoleLog)
      console.log('remaining volume: '+remainingVolume);
  }*/
}

function getSandMultiplier(sandPercentage: number, hasSand: boolean, hasGravel: boolean) {
  if(hasSand && !hasGravel) return 1;
  if(hasSand && hasGravel) return sandPercentage / 100;
  if(!hasSand && hasGravel) return sandPercentage / 100;
  return 0;
}
function getGravelMultiplier(sandPercentage: number, hasSand: boolean, hasGravel: boolean) {
  if(hasGravel && !hasSand) return 1;
  if(hasGravel && hasSand) return 1 - sandPercentage / 100;
  if(!hasGravel && hasSand) return 1 - sandPercentage / 100;
  return 0;
}
