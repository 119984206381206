import React, { useState, useContext, useCallback, useEffect } from 'react';
import { Typography, AppBar, Toolbar, IconButton, Icon, Button, Menu, MenuItem, ListItemIcon, Tabs, Tab, makeStyles, Grid, ButtonBase, Box, Popover } from '@material-ui/core';
import { AppContext } from '../App';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {Settings} from "../config/settings";

const apps = [
  { href: Settings.URLs.labApp, title: 'Lab', icon: require('../images/lab-icon.png').default },
  { href: Settings.URLs.planningApp.root, title: 'Orders', icon: <Icon fontSize="large">assignment</Icon>, disabled: true }
]

const useStyles = makeStyles({
  indicator: {
    background: '#fff'
  }
})

type AppToolbarProps = RouteComponentProps & {
  user: { name: string }
}

const AppToolbar: React.FC<AppToolbarProps> = ({ user, history, location }) => {
  const { logout } = useContext(AppContext);
  const [appsAnchorEl, setAppsAnchorEl] = useState(null as null | HTMLElement);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null as null | HTMLElement);
  const [darkMode, setDarkMode] = useState(false)
  const [tab, setTab] = useState('/recipe');

  const classes = useStyles();

  useEffect(() => {
    let setting=localStorage.getItem('dark-mode')
    if(setting!==null && setting) {
      let dm=JSON.parse(setting) as Boolean
      // @ts-ignore
      setDarkMode(dm)
      handleDarkMode(dm)
    }
  },[darkMode])

  useEffect(() => {
    const tab = location.pathname.split('/').slice(1).shift();
    setTab('/' + tab);
  }, [location]);

  function handleDarkMode(darkMode: Boolean) {
    if(darkMode)
      document.body.classList.add('dark-mode')
    else
      document.body.classList.remove('dark-mode')
    localStorage.setItem('dark-mode', JSON.stringify(darkMode))
  }

  const handleLogout = useCallback(() => {
    logout();
    setMenuAnchorEl(null);
  }, [logout]);

  const handleMenuItemClick = useCallback((path: string) => {
    history.push(path);
    setMenuAnchorEl(null);
  }, [history]);

  const handleTabChange = useCallback((e, path: string) => {
    history.push(path);
  }, [history]);

  const toggleDarkMode = useCallback(() => {
    setDarkMode(!darkMode)
    handleDarkMode(!darkMode)
  }, [darkMode])

  return (
    <AppBar color="primary">
      <Toolbar>
        <Typography variant="h5">Receptuur</Typography>
        <Tabs value={(tab === '/recipe' || tab === '/resource' || tab === '/attest') ? tab : false} onChange={handleTabChange} classes={classes}>
          <Tab label="Recepten" value="/recipe" style={{ height: 64 }} />
          <Tab label="Grondstoffen" value="/resource" style={{ height: 64 }} />
        </Tabs>
        <span style={{ flex: 1 }} />
        <IconButton onClick={e => setAppsAnchorEl(e.currentTarget)} color="inherit">
          <Icon>apps</Icon>
        </IconButton>
        <Popover anchorEl={appsAnchorEl} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} transformOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={Boolean(appsAnchorEl)} onClose={() => setAppsAnchorEl(null)}>
          <Grid container={true} spacing={3} style={{ margin: 16, maxWidth: 200, width: 145 }} justifyContent="center" wrap="wrap">
            {apps.map(({ title, href, icon }, k) => (
              <Grid item={true} key={k}>
                <ButtonBase onClick={() => (window.location.href = href)} >
                  {/* @ts-ignore */}
                  <Box display="flex" flexDirection="column" alignItems="center">
                    {typeof icon === 'string' ? <img src={icon} alt={title} width={36} /> : icon}
                    <Typography variant="subtitle2">{title}</Typography>
                  </Box>
                </ButtonBase>
              </Grid>
            ))}
          </Grid>
        </Popover>
        <Button onClick={(e) => toggleDarkMode()} color="inherit">
          {!darkMode && <Icon>
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#fff">
              <path
                d="M480-120q-150 0-255-105T120-480q0-150 105-255t255-105q14 0 27.5 1t26.5 3q-41 29-65.5 75.5T444-660q0 90 63 153t153 63q55 0 101-24.5t75-65.5q2 13 3 26.5t1 27.5q0 150-105 255T480-120Zm0-80q88 0 158-48.5T740-375q-20 5-40 8t-40 3q-123 0-209.5-86.5T364-660q0-20 3-40t8-40q-78 32-126.5 102T200-480q0 116 82 198t198 82Zm-10-270Z"/>
            </svg>
          </Icon>}
          {darkMode && <Icon>
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#fff">
              <path
                d="M480-360q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35Zm0 80q-83 0-141.5-58.5T280-480q0-83 58.5-141.5T480-680q83 0 141.5 58.5T680-480q0 83-58.5 141.5T480-280ZM200-440H40v-80h160v80Zm720 0H760v-80h160v80ZM440-760v-160h80v160h-80Zm0 720v-160h80v160h-80ZM256-650l-101-97 57-59 96 100-52 56Zm492 496-97-101 53-55 101 97-57 59Zm-98-550 97-101 59 57-100 96-56-52ZM154-212l101-97 55 53-97 101-59-57Zm326-268Z"/>
            </svg>
          </Icon>}
        </Button>
        <Button onClick={(e) => setMenuAnchorEl(e.currentTarget)} color="inherit">
          <Icon>account_circle</Icon>&nbsp;&nbsp;
          {user.name}
        </Button>
        <Menu anchorEl={menuAnchorEl} open={Boolean(menuAnchorEl)} disableAutoFocusItem={true}
              onClose={() => setMenuAnchorEl(null)}>
          <MenuItem onClick={() => handleMenuItemClick('/settings')} selected={tab==='/settings'}>
            <ListItemIcon><Icon>settings</Icon></ListItemIcon> Instellingen
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <ListItemIcon><Icon>power_settings_new</Icon></ListItemIcon> Uitloggen
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

export default withRouter(AppToolbar);
