import React, {Fragment} from "react";
import {missingPlant, Plant, ResourceType} from "../../types";
import {Alert, AlertTitle} from "@material-ui/lab";

type MissingPlantsAlertProps = {
  plants: Plant[]
  missingPlants: missingPlant[]
  bottomMargin: boolean
}

export const MissingPlantsAlert: React.FC<MissingPlantsAlertProps> = ({ plants, missingPlants, bottomMargin }) => {
  return (
    <Fragment>
      {missingPlants.length>0 && <Alert severity="warning" style={{marginBottom: bottomMargin ? '1rem' : '0'}}>
        <AlertTitle>Ontbrekende grondstoffen</AlertTitle>
        {missingPlants.map(missing => (
          <Fragment key={missing.name}>
            <div>
              Grondstof <span><b>{missing.name}</b> is niet in gebruik bij </span>
              {missing.plantIds.map((plantId, index) => {
                let plant=plants.find(p => p.id===plantId);
                let comma=index<missing.plantIds.length-1 ? ', ' : ''
                return (
                  <span key={plant.id}><b>{plant.name}</b>{comma}</span>
                )
              })}
            </div>
          </Fragment>
        ))}
      </Alert>}
    </Fragment>
  )
}
