import React, {ChangeEvent, useCallback, useContext, useState} from 'react';
import { Step } from './RecipeDialogContent';
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText, makeStyles,
  MenuItem,
  Select, Theme
} from '@material-ui/core';
import {StoreContext} from "../../StoreContextProvider";
import {missingPlant} from "../../types";
import {validatePlants} from "../../HTTPClients/RecipeApp/resources/resources";
import {MissingPlantsAlert} from "./MissingPlantsAlert";
import {Settings} from "../../config/settings";

const useStyles = makeStyles((theme: Theme) => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '-8.5px 0'
  },
  chip: {
    margin: 2,
  }
}));

const MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  PaperProps: {
    style: { maxHeight: 342}
  },
  getContentAnchorEl: null,
  variant: 'menu'
};

const plantsStep: Step = {
  title: 'Vestiging(en)',
  hasErrors: recipe => false,
  isCompleted: recipe => recipe.plants && recipe.plants.length > 0,
  optionalText: recipe => recipe.plants ? recipe.plants.map(p => p.name).join(', ') : '',
  StepComponent: ({ recipe, onChange }) => {
    const {plants} = recipe
    const [missingPlants, setMissingPlants] = useState([] as missingPlant[])
    const classes = useStyles()
    const plantOptions=useContext(StoreContext).plants;

    const handleChange = useCallback((e: ChangeEvent<{ value: unknown }>) => {
      let selection=plantOptions.filter(p => p.id && (e.target.value as unknown as number[]).indexOf(p.id) >= 0)
      if(selection.length)
        onChange({ ...recipe, plants: [...selection] });

      validatePlants(recipe).then(
        function(missingPlants) {
          setMissingPlants(missingPlants)
        }
      )
    }, [onChange, plantOptions]);

    return (
      <Box marginBottom={2} marginTop={1}>
        {/* @ts-ignore */}
        <MissingPlantsAlert plants={recipe.plants} missingPlants={missingPlants}></MissingPlantsAlert>

        <FormControl fullWidth={true} variant="outlined">
          <InputLabel htmlFor="select-multipe-plants" required={true}>Selecteer centrale(s)</InputLabel>
          <Select
            labelWidth={0}
            label={'Selecteer centrale(s)'}
            multiple={true}
            value={plants.map(p => p.id)}
            onChange={handleChange}
            inputProps={{id: 'select-multipe-plants',}}
            renderValue={selected => (
              <div className={classes.chips}>
                {(selected as number[]).map(id => {
                  const plant = plants.find(p => p.id === id);
                  return (
                    <Chip key={id} label={plant && plant.name} className={classes.chip} />
                  )
                })}
              </div>
            )}
            MenuProps={MenuProps}
          >
            {plantOptions.map(plant => (
              <MenuItem key={plant.id} value={plant.id}>
                <Checkbox checked={plants.map(p => p.id).indexOf(plant.id) > -1} disabled={plant.id===Settings.default_plant_id} />
                <ListItemText primary={plant.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    )
  }
}

export default plantsStep
