import {Settings} from "../../../config/settings";
import axiosHttpClient from "../axiosHttpClient";

export async function post(data: any) {
	return axiosHttpClient.post('/recipe/'+Settings.default_plant_id, data);
}

export async function copy(id: number) {
	return axiosHttpClient.get('/recipe/copy/'+id.toString());
}

export async function publish(id: number) {
	return axiosHttpClient.get('/recipe/publish/'+id.toString());
}

export async function publishBatch(ids: number[]) {
	return axiosHttpClient.post('/recipes/publish/batch', ids);
}

export async function unPublish(id: number) {
	return axiosHttpClient.get('/recipe/unpublish/'+id.toString());
}

export async function unPublishBatch(ids: number[]) {
	return axiosHttpClient.post('/recipes/unpublish/batch', ids);
}

export async function archive(id: number) {
	return axiosHttpClient.get('/recipe/archive/'+id.toString());
}

export async function archiveBatch(ids: number[]) {
	return axiosHttpClient.post('/recipes/archive/batch', ids);
}

export async function getAll(plantIds: Number[]=[], archived: boolean=false) {
	let param='';
	if(archived!==null) {
		param=archived ? 'true' : 'false';
		param='/'+param;
	}
  let params=null; if(plantIds.length) params={params: { plantIds: plantIds.join(',')}}
	return axiosHttpClient.get('/recipes'+param, params);
}

export async function getWithErrors(plantIds: Number[]=[]) {
  let params=null; if(plantIds.length) params={params: { plantIds: plantIds.join(',')}}
  return axiosHttpClient.get('/recipes/errors', params);
}

export async function getOne(public_id: number) {
	return axiosHttpClient.get('/recipe/one/'+public_id.toString()+'/'+Settings.default_plant_id);
}

export async function getById(id: number) {
  return axiosHttpClient.get('/recipe/id/'+id.toString()+'/'+Settings.default_plant_id);
}

export async function getResources(recipeId: number, plantId: number) {
  return axiosHttpClient.get('/recipe/resources/'+recipeId.toString()+'/'+plantId.toString()+'/'+Settings.default_plant_id);
}

export async function getArticles(id: number) {
  return axiosHttpClient.get('/recipes/read/articles/'+id.toString());
}

export async function getArticleSelectList() {
  return axiosHttpClient.get('/recipes/read/articles');
}

export async function addArticle(data: any) {
  return axiosHttpClient.post('/recipes/create/articles', data);
}

export async function removeArticle(id: number) {
  return axiosHttpClient.delete('/recipes/delete/articles/'+id.toString());
}

export async function getWithResource(resourceId: number) {
	return axiosHttpClient.get('/recipes/with-resource/'+resourceId.toString()+'/'+Settings.default_plant_id);
}

export async function getWithWorktype(worktype_id: number) {
  return axiosHttpClient.get('/recipes/with-worktype/'+worktype_id.toString()+'/'+Settings.default_plant_id);
}
