import React, {ChangeEvent, Fragment, useCallback, useEffect, useState} from 'react';
import {
  Addition,
  Cement,
  Excipient,
  Extra,
  Family,
  Filler,
  missingPlant,
  Recipe,
  RecipeRevision,
  ResourceType
} from '../../types';
import {
  Avatar,
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  Grid,
  Icon, Link,
  List,
  ListItem, ListItemAvatar,
  ListItemText,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core';
import RecipeSummary from './RecipeSummary';
import DeliveryOverview from './DeliveryOverview';
import GradingCurveOverview from './GradingCurveOverview';
import Autocomplete from '../Autocomplete';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import RevisionListItem from './RevisionListItem';
import useAuthorized from '../../useAuthorized';
import {EnhancedRecipe} from '../../pages/Recipes';
import {getAll} from '../../HTTPClients/RecipeApp/recipes/families';
import {Settings} from "../../config/settings";
import RecipeArticles from "./RecipeArticles";
import {getResources} from "../../HTTPClients/RecipeApp/recipes/recipes";
import {setPlantRelatedResources} from "./serviceFunctions";
import {getPlant, validatePlants} from "../../HTTPClients/RecipeApp/resources/resources";
import {MissingPlantsAlert} from "./MissingPlantsAlert";

const fields = [
  { name: 'nameExternal', label: 'Code (extern)', display: ['external'], xs: 12 as 12 },
  { name: 'remarksInternal', label: 'Opmerkingen',display: ['internal'], multiline: true, xs: 12 as 12 },
  { name: 'remarksExternal', label: 'Opmerkingen', display: ['external'], multiline: true, xs: 12 as 12 },
]

let changeTimeout: NodeJS.Timeout;

const RecipeField: React.FC<{ id: string, label: string, disabled?: boolean, multiline?: boolean, defaultValue?: string, onChange: (value: string) => void }> = ({ id, label, disabled, multiline, onChange, defaultValue }) => {
  const [value, setValue] = useState(defaultValue);
  useEffect(() => setValue(defaultValue), [defaultValue])
  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setValue(value);
    clearTimeout(changeTimeout);
    changeTimeout = setTimeout(() => { onChange(value); }, 150);
  }, [onChange]);
  return (
    <TextField
      id={id}
      label={label}
      variant="outlined"
      size={'medium'}
      value={value || ''}
      onChange={handleChange}
      margin="normal"
      fullWidth={true}
      disabled={disabled}
      multiline={multiline}
      {...multiline && { minRows: 5 }}
    />
  )
}

const RecipeDetailsFields: React.FC<{ recipe: Recipe, displayType: 'internal' | 'external', onChange: (recipe: Recipe) => void }> = ({ recipe, displayType, onChange }) => {
  const canEdit = useAuthorized(['update:recipes'])
  const canEditRemarksExternal = useAuthorized(['update:recipes_remarks_external'])
  const canEditRemarksInternal = useAuthorized(['update:recipes_remarks_internal'])
  const handleChange = useCallback((property: string, value: string) => {
    recipe.remarksInternal=value;
    onChange({ ...recipe, [property]: value });
  }, [recipe, onChange]);
  return (
    <Fragment>
      {fields.filter(f => f.display.indexOf(displayType) >= 0).map(({ name, label, display, multiline, ...gridProps }) => (
        <Grid key={name} id={name} item={true} {...gridProps}>
          <RecipeField
            id={name+'1'}
            label={label}
            defaultValue={recipe[name as keyof Recipe] as string || ''}
            onChange={value => handleChange(name, value)}
            multiline={multiline}
            {...multiline && { rows: 5 }}
            disabled={!(canEdit || (name === 'remarksExternal' && canEditRemarksExternal) || (name === 'remarksInternal' && canEditRemarksInternal))}
          />
        </Grid>
      ))}
    </Fragment>
  )
}

const ConfirmationStep: React.FC<{ recipe: Recipe | EnhancedRecipe, onChange: (recipe: Recipe | EnhancedRecipe) => void, onSave: () => void, plants: Array<any>, plantId: number, onPlantChange: (plantId: number) => void, goto: (step: number) => void }> = ({ recipe, onChange, onSave, plants, plantId, onPlantChange, goto }) => {
  const [displayType, setDisplayType] = useState('internal' as 'internal' | 'external');
  const canEdit = useAuthorized(['update:recipes'])
  const [republish, setRepublish] = useState(true)
  const [families, setFamilies] = useState([])
  const [plant, setPlant] = useState(plantId)
  const [gotFamilies, setGotFamilies] = useState(false)
  const isDefaultPlant=plantId===Settings.default_plant_id
  const [missingPlants, setMissingPlants] = useState([] as missingPlant[])

  useEffect(()=> {
    setPlant(plantId)
  },[plantId])

  useEffect(()=> {
    if(gotFamilies) return;
    getAll().then(
        function(response) {
          setGotFamilies(true);
          setFamilies(response.data.data);
        }
    )
  },[setFamilies, families, gotFamilies])

  useEffect(()=> {
    // @ts-ignore
    validatePlants(recipe).then(
      function(missingPlants) {
        setMissingPlants(missingPlants)
      }
    )
  },[recipe])

  const [revisionsLimited, setRevisionsLimited]=useState(true);

  const [name, setName] = useState('');
  useEffect(() => { setName(recipe.recipeName) }, [recipe, name, setName])

  const getSuggestions = useCallback(async (value: string) => {
    const f: Family[] = families.length ? (families as Family[]).filter((family: Family) => (recipe.families ? recipe.families.map(f => f.id) : []).indexOf(family.id) < 0 && family.name.toLowerCase().indexOf(value.toLowerCase()) >= 0) : []
    return [...f, { name: value, isNew: true }]
  }, [families, recipe])

  const renderSuggestion = useCallback((suggestion: Family & { isNew: boolean }, { query, isHighlighted }: Autosuggest.RenderSuggestionParams) => {
    const matches = match(suggestion.name, query);
    const parts = parse(suggestion.name, matches);
    return (
      <MenuItem selected={isHighlighted} component="div">
        <div>
          {parts.map(part => (
            <span key={part.text} style={{ fontWeight: part.highlight ? 500 : 400 }}>
              {part.text}
            </span>
          ))}
          {suggestion.isNew && <span> aan de lijst toevoegen</span>}
        </div>
      </MenuItem>
    );
  }, []);

  const onDelete = useCallback((chip, index) => {
    const { families } = recipe;
    if (families) {
      families.splice(index, 1);
      onChange({ ...recipe, families });
    }
  }, [onChange, recipe]);

  const handleRepublish = useCallback((event) => {
    setRepublish(event.target.checked);
    recipe['republish']=event.target.checked;
  },[setRepublish, recipe]);

  const nameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setName(value);
    recipe.recipeName=value;
    onChange(recipe)
  }, [setName, recipe, onChange]);

  const plantChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    let value = parseInt(e.target.value);
    setPlant(value);

    if(!recipe.id) {
      getPlant(value).then(
        function(response) {
          setPlantRelatedResources(recipe, response.data.data)
          onPlantChange(value);
          onChange(recipe);
        }
      )
    } else {
      getResources(recipe.id, value).then(
        function(response) {
          setPlantRelatedResources(recipe, response.data.data)
          onPlantChange(value);
          onChange(recipe);
        }
      )
    }
  },[recipe, setPlant, onPlantChange, onChange]);

  return (
    <Grid container={true} spacing={2} wrap="wrap">
      <Grid item={true} xs={12} className="no-print">
        <Typography variant="h5">Overzicht recept <span hidden={!recipe.id}>{recipe.id}</span></Typography>
      </Grid>
      <Grid item={true} xl={4} lg={3} className="no-print">
        <Grid container={true} spacing={1} wrap="wrap">
          <Grid item={true} xs={12} style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
            <Typography>Weergave:</Typography>&nbsp;&nbsp;
            <ButtonGroup color="primary" size="small" style={{ height: 32 }}>
              <Button onClick={() => setDisplayType('internal')} {...displayType === 'internal' && { variant: 'contained' }} style={{ boxShadow: 'none' }}>Intern</Button>
              <Button onClick={() => setDisplayType('external')} {...displayType === 'external' && { variant: 'contained' }} style={{ boxShadow: 'none' }}>Extern</Button>
            </ButtonGroup>
          </Grid>
          <RecipeDetailsFields recipe={recipe} onChange={onChange} displayType={displayType} />
          <Grid item={true} xs={12}>
            <Autocomplete
              value={recipe.families || []}
              label="Kies bijbehorende familie groepen"
              renderSuggestion={renderSuggestion}
              getSuggestions={getSuggestions}
              getSuggestionValue={({ name }) => name}
              onChange={({ name, id }) => onChange({ ...recipe, families: [...recipe.families || [], { name, id }] })}
              onDelete={onDelete}
              variant="outlined"
              fullWidth={true}
              margin="normal"
              multiple={true}
              disabled={!canEdit}
            />
          </Grid>
        </Grid>
        <Grid item={true} xs={12} style={{ marginTop: 16 }}>
          <Button variant="contained" color="primary" disabled={!isDefaultPlant || !recipe.recipeName || missingPlants.length} onClick={onSave} style={{ marginRight: 8 }}><Icon>save</Icon>&nbsp;&nbsp;Opslaan</Button>
          <Button onClick={() => window.print()}><Icon>print</Icon>&nbsp;&nbsp;Afdrukken</Button>
        </Grid>
        <Grid item={true} xs={12} style={{ marginBottom: 16 }}>
          <FormControlLabel label="Recept publiceren na opslaan"
              control={<Checkbox disabled={!recipe.recipeName || !canEdit}
              checked={republish} onChange={handleRepublish} />}
          />
        </Grid>
        {recipe.revisions && recipe.revisions.length > 1 &&
          <Grid item={true} xs={12} style={{ marginTop: 16 }}>
            <Typography variant="subtitle2">{`Actuele versie`}</Typography>
            <List dense={true} className={'revisions-list'}>
              <RevisionListItem revision={recipe.revisions[0] as RecipeRevision & { user: { name: string, picture: string } }} recipe={recipe} clickable={false} key={0} />
            </List>
          </Grid>
        }
        {recipe.revisions && recipe.revisions.length > 1 &&
            <Grid item={true} xs={12} style={{ marginTop: 16 }}>
            <Typography variant="subtitle2">{`Vorige versie${recipe.revisions.length > 0 && 's'}`}</Typography>
            {recipe.revisions.length>11 &&
              <FormControlLabel
                  control={<Checkbox checked={!revisionsLimited} onChange={() => setRevisionsLimited(!revisionsLimited)} />}
                  label={<Typography variant="body2">Toon alle {recipe.revisions.length-1} versies</Typography>}
              />}
            <List dense={true} className={'revisions-list'}>
              {recipe.revisions.slice(1, revisionsLimited ? 10 : 1000).map((revision, k) => {
                return (
                  <RevisionListItem revision={revision as RecipeRevision & { user: { name: string, picture: string } }} recipe={recipe} clickable={true} key={k} />
                )
              })}
            </List>
          </Grid>
        }
      </Grid>
      <Grid item={true} xl={8} lg={9} className="print-container">
        <div className="print-overview" style={{ display: 'none' }}>
          <Typography variant="h5">Betonsamenstelling en korrelverdeling</Typography>
          <Grid container={true} justifyContent="space-between" spacing={2}>
            <Grid item={true}>
              <ListItem dense={true}>
                <ListItemText primary="Nr." secondary={recipe.id} />
              </ListItem>
            </Grid>
            <Grid item={true}>
              <ListItem dense={true}>
                <ListItemText primary="Receptnaam" secondary={recipe.recipeName} />
              </ListItem>
            </Grid>
            {displayType === 'external' && <Grid item={true}>
              <ListItem dense={true}>
                <ListItemText primary="Code" secondary={recipe.codeExternal} />
              </ListItem>
            </Grid>}
            <Grid item={true} style={{ flex: 1 }}>
              <ListItem dense={true}>
                <ListItemText primary="Opmerkingen" secondary={displayType === 'internal' ? recipe.remarksInternal : recipe.remarksExternal} />
              </ListItem>
            </Grid>
          </Grid>
        </div>
        <TextField
            id={'recipeName'}
            label={'Receptnaam'}
            variant="outlined"
            size={'small'}
            value={name || ''}
            margin="normal"
            fullWidth={true}
            onChange={nameChange}
        />
        {plantId>0 && <TextField
          label="Vestiging"
          value={plant}
          onChange={plantChange}
          variant="filled"
          className={'plantSelector'}
          size={'small'}
          fullWidth={true}
          select={true}
          disabled={plants.length<2}
        >
          {plants.map( (plant) => {
            // @ts-ignore
            if(recipe.plants.findIndex(p=>p.id===plant.id)>-1)
              return <MenuItem key={plant.id} value={plant.id}>{plant.name}</MenuItem>
          })}
        </TextField>}

        {/* @ts-ignore */}
        <MissingPlantsAlert plants={recipe.plants} missingPlants={missingPlants}></MissingPlantsAlert>

        <DeliveryOverview recipe={recipe} displayType={displayType} defaultPlant={isDefaultPlant} goto={goto} />
        <RecipeSummary recipe={recipe} style={{ marginTop: 16 }} displayType={displayType} defaultPlant={isDefaultPlant} onChange={onChange} goto={goto} />
        <RecipeArticles recipe={recipe}></RecipeArticles>
        <GradingCurveOverview recipe={recipe} goto={goto} style={{ marginTop: 16 }} />
      </Grid>
    </Grid>
  )
}

export default ConfirmationStep;
